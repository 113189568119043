import { useState, useEffect } from 'react';

import type { HeroIcon } from '~/types';

import { cn } from '~/utils';

type valueOption = {
  label: string;
  value: string;
  Icon?: HeroIcon;
};

type ToggleProps = {
  falseOption: valueOption;
  trueOption: valueOption;
  defaultValue: string;
  value?: string;
  onClick: (val: string) => void;
  disabled?: boolean;
  wrapperClassName?: string;
};

const RichToggle = ({
  falseOption,
  trueOption,
  defaultValue,
  value: controlledValue,
  onClick,
  disabled = false,
  wrapperClassName,
}: ToggleProps) => {
  const [value, setValue] = useState<string>(() => defaultValue);

  const handleClick = () => {
    setValue(() =>
      value === trueOption.value ? falseOption.value : trueOption.value,
    );
    onClick(value === trueOption.value ? falseOption.value : trueOption.value);
  };

  const { Icon: TrueIcon } = trueOption;
  const { Icon: FalseIcon } = falseOption;

  useEffect(() => {
    if (typeof controlledValue !== 'undefined') {
      setValue(controlledValue);
    }
  }, [controlledValue]);

  return (
    <div
      className={cn(
        'relative flex h-10 w-full items-center justify-center gap-1 rounded-md bg-muted p-1 text-muted-foreground',
        wrapperClassName,
        {
          'cursor-not-allowed opacity-60': disabled,
        },
      )}
    >
      <OptionLabel
        onClick={handleClick}
        label={falseOption.label}
        Icon={FalseIcon}
        disabled={disabled}
        active={value === falseOption.value}
      />

      <OptionLabel
        onClick={handleClick}
        label={trueOption.label}
        Icon={TrueIcon}
        disabled={disabled}
        active={value === trueOption.value}
      />

      <div
        className={cn(
          'bg-back absolute left-0 inline-flex h-[calc(100%-8px)] w-[calc(50%-6px)] translate-x-1 cursor-pointer items-center justify-center gap-2 whitespace-nowrap rounded-[6px] border border-neutral-250 bg-background px-3 py-1.5 text-sm font-medium ring-offset-background transition-all focus-visible:outline-none focus-visible:ring-2 focus-visible:ring-ring focus-visible:ring-offset-2 disabled:pointer-events-none disabled:opacity-50',
          {
            'translate-x-[calc(100%+8px)]': value === trueOption.value,
          },
        )}
      />
    </div>
  );
};

type OptionProps = {
  label: string;
  Icon?: HeroIcon;
  disabled: boolean;
  active: boolean;
  onClick: () => void;
};

const OptionLabel = ({
  label,
  Icon,
  disabled,
  active,
  onClick,
}: OptionProps) => (
  <button
    type="button"
    className={cn(
      'z-10 inline-flex w-full cursor-pointer items-center justify-center gap-2 whitespace-nowrap rounded-sm px-3 py-1.5 text-sm font-medium ring-offset-background transition-all focus-visible:outline-none focus-visible:ring-2 focus-visible:ring-ring focus-visible:ring-offset-2 disabled:pointer-events-none disabled:opacity-50',
      {
        'cursor-not-allowed': disabled,
        'shadow-sm text-foreground': active,
      },
    )}
    onClick={onClick}
  >
    {Icon && <Icon className="h-4 w-4" />}

    {label}
  </button>
);

export default RichToggle;
