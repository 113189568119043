import { useState } from 'react';

import { v4 as uuid } from 'uuid';

import { PlusIcon, TrashIcon } from '@heroicons/react/24/solid';

import type { InputProps } from 'react-select';

import { correctURL } from '~/utils';

import Button from '../Button';
import Input from './Input';
export type ArrayInputProps = InputProps & {
  defaultValue?: string[];
  onBlurNormaliseURL?: boolean;
  onChange?: (updateObj: string[] | { value: string; index: number }) => void;
  overrideValues?: { [key: string]: string[] };
};

type ArrayInputValue = {
  id: string;
  value: string;
};

const ArrayInput = ({
  defaultValue = [],
  onChange,
  overrideValues,
  onBlurNormaliseURL = false,
  ...props
}: ArrayInputProps) => {
  const [arrayValues, setArrayValues] = useState<ArrayInputValue[]>(() =>
    defaultValue?.length > 0
      ? defaultValue.map((val) => ({ id: uuid(), value: val }))
      : [{ id: uuid(), value: '' }],
  );

  return (
    <>
      {arrayValues.map(({ id, value }, index) => {
        const isLast = index === arrayValues.length - 1;
        return (
          <div key={id} className="flex items-center justify-between gap-2">
            <Input
              {...props}
              {...(onChange && {
                onChange: (e) => {
                  const updatedArrayValues = [...arrayValues];
                  updatedArrayValues[index] = {
                    id,
                    value: e.target.value,
                  };

                  setArrayValues(updatedArrayValues);
                  onChange &&
                    onChange(updatedArrayValues.map((item) => item.value));
                  e.stopPropagation();
                },
              })}
              onBlur={(e) => {
                props.onBlur && props.onBlur(e);
                if (onBlurNormaliseURL && e.target.value !== '') {
                  const updatedArrayValues = [...arrayValues];
                  updatedArrayValues[index] = {
                    id,
                    value: correctURL(e.target.value),
                  };
                  setArrayValues(updatedArrayValues);
                  onChange &&
                    onChange(updatedArrayValues.map((item) => item.value));
                }
              }}
              value={value}
              autoComplete={props.name}
              containerClassName="w-full"
            />
            {isLast ? (
              <Button
                disabled={!value.length}
                variant="icon"
                Icon={PlusIcon}
                onClick={() => {
                  setArrayValues((prev) => [
                    ...prev,
                    {
                      id: uuid(),
                      value: '',
                    },
                  ]);
                  onChange &&
                    onChange([...arrayValues.map((item) => item.value), '']);
                }}
              />
            ) : (
              <Button
                Icon={TrashIcon}
                variant="icon"
                iconClassName="text-secondary-300"
                onClick={() => {
                  setArrayValues((prev) =>
                    prev.filter((item) => item.id !== id),
                  );
                  onChange &&
                    onChange(
                      arrayValues
                        .filter((_, i) => i !== index)
                        .map((item) => item.value),
                    );
                }}
              />
            )}
          </div>
        );
      })}
    </>
  );
};

export default ArrayInput;
