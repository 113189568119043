import type { FC } from 'react';

import type { WidgetTemplate } from '~/models';

import { OverlayTemplate } from './OverlayTemplate';
import { InlineTemplate } from './InlineTemplate';
import { EmbeddedTemplate } from './EmbeddedTemplate';
import { LauncherTemplate } from './LauncherTemplate';

const templateSVGMap: Record<WidgetTemplate, FC> = {
  overlay: OverlayTemplate,
  inline: InlineTemplate,
  embedded: EmbeddedTemplate,
  launcher: LauncherTemplate,
};

export const TemplateSVG = ({ template }: { template: WidgetTemplate }) => {
  const Template = templateSVGMap[template];

  return <Template />;
};
