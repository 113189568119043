import { forwardRef, useCallback, useMemo, useState } from 'react';
import type { ForwardedRef } from 'react';

import { useSubmit } from '@remix-run/react';

import { useField } from 'remix-validated-form';
import clsx from 'clsx';

import {
  ArrowRightCircleIcon,
  MagnifyingGlassIcon,
  XCircleIcon,
} from '@heroicons/react/24/solid';
import { MinusIcon, PlusIcon } from '@heroicons/react/20/solid';

import type { HeroIcon } from '~/types';

import { Text } from '../_legacy/Typography';
import Button from '../Button';

const placeholderClasses =
  'placeholder:text-sm placeholder:leading-normal placeholder:text-grey placeholder:font-medium';

export type InputProps = {
  LeftIcon?: HeroIcon;
  RightIcon?: HeroIcon;
  leftIconClassName?: string;
  rightIconClassName?: string;
  rightIconClickHandler?: () => void;
  containerClassName?: string;
  onClearSearch?: () => void;
  limit?: number;
  numberInputVariant?: 'default' | 'buttonControls';
  unit?: string;
} & JSX.IntrinsicElements['input'];

export const ValidatedInput = forwardRef<
  HTMLInputElement | HTMLTextAreaElement,
  InputProps
>(
  (
    {
      className,
      type,
      LeftIcon,
      RightIcon,
      leftIconClassName,
      rightIconClassName,
      rightIconClickHandler,
      containerClassName,
      onClearSearch,
      limit,
      numberInputVariant = 'default',
      unit,
      ...props
    }: InputProps,
    ref,
  ) => {
    const submit = useSubmit();

    const [range, setRange] = useState(props.defaultValue);

    if (type === 'search') {
      LeftIcon = MagnifyingGlassIcon;
    }

    const inputLength = useMemo(() => {
      if (ref != null && typeof ref !== 'function' && ref.current) {
        return ref.current.value.length;
      }

      if (props.defaultValue) {
        return props.defaultValue.toString().length;
      }

      if (props.value) {
        return props.value.toString().length;
      }

      return 0;
    }, [ref, props.defaultValue, props.value]);

    const handleIncrease = useCallback(() => {
      if (ref != null && typeof ref !== 'function' && ref.current) {
        const current = parseInt(ref.current.value, 10);

        if (isNaN(current)) return;

        const newValue = (
          props.max && current >= parseInt(props.max.toString(), 10)
            ? current
            : current + 1
        ).toString();

        const input = ref.current;

        const nativeInputValueSetter = Object.getOwnPropertyDescriptor(
          input.constructor.prototype,
          'value',
        )?.set;

        nativeInputValueSetter?.call(input, newValue);

        const e = new Event('input', { bubbles: true });
        input.dispatchEvent(e);
      }
    }, [ref, props.max]);

    const handleDecrease = useCallback(() => {
      if (ref != null && typeof ref !== 'function' && ref.current) {
        const current = parseInt(ref.current.value, 10);

        if (isNaN(current)) return;

        const newValue = (
          props.min && current <= parseInt(props.min.toString(), 10)
            ? current
            : current - 1
        ).toString();

        const input = ref.current;

        const nativeInputValueSetter = Object.getOwnPropertyDescriptor(
          input.constructor.prototype,
          'value',
        )?.set;

        nativeInputValueSetter?.call(input, newValue);

        const e = new Event('input', { bubbles: true });
        input.dispatchEvent(e);
      }
    }, [ref, props.min]);

    const sharedClassName = clsx(
      'peer w-full rounded-md px-4 py-3 text-sm font-normal leading-normal text-dark outline-none placeholder:font-normal focus:caret-blue-light disabled:cursor-not-allowed',
      'duration-250 border border-transparent bg-neutral-150 shadow-neutral-150 transition-all placeholder:text-neutral-400 hover:border-neutral-250 hover:bg-neutral-0 hover:shadow focus:border-neutral-300 focus:bg-neutral-0 focus:shadow-solid active:border-neutral-250 active:shadow-none',
      placeholderClasses,
      { 'pl-12': !!LeftIcon },
      { 'pr-12': !!RightIcon },
      {
        'text-center [appearance:textfield] [&::-webkit-inner-spin-button]:appearance-none [&::-webkit-outer-spin-button]:appearance-none':
          type === 'number' && numberInputVariant === 'buttonControls',
      },
      className,
    );

    if (type === 'range') {
      const { defaultValue, ...rest } = props;
      return (
        <div className="-mt-2 flex items-center gap-4">
          <input
            type="range"
            {...rest}
            value={range}
            className={clsx('[&::-webkit-slider-thumb]:!bg-accent-purple-400')}
            onChange={(e) => setRange(parseInt(e.target.value))}
          />
          <div className="flex flex-col gap-2">
            <Button
              onClick={() => {
                setRange(props.defaultValue);
              }}
              className="!border-neutral-250 !bg-neutral-50 !px-3 !py-2 !shadow-none"
            >
              Reset
            </Button>
            <span className="relative w-16 rounded-sm bg-accent-purple-400 px-2 py-1 text-center text-white">
              <Text className="!text-white">
                {range}
                {unit || ''}
              </Text>
            </span>
          </div>
        </div>
      );
    }

    if (type === 'textarea') {
      return (
        <div
          className={clsx(
            'group relative text-grey focus-within:text-blue-light',
            containerClassName,
          )}
        >
          <textarea
            {...(props as JSX.IntrinsicElements['textarea'])}
            className={clsx('min-h-[5rem]', sharedClassName)}
            ref={ref as ForwardedRef<HTMLTextAreaElement>}
            {...(limit && { maxLength: limit })}
          />

          {limit && (
            <Text
              className="absolute bottom-2 right-4 -translate-y-1/2 transform bg-transparent"
              as="span"
              variant="faded"
              size="sm"
            >
              {inputLength} / {limit}
            </Text>
          )}
        </div>
      );
    }

    return (
      <div
        className={clsx(
          'group relative text-grey focus-within:text-blue-light',
          containerClassName,
          {
            'flex gap-2':
              type === 'number' && numberInputVariant === 'buttonControls',
          },
        )}
      >
        {type === 'number' && numberInputVariant === 'buttonControls' && (
          <Button
            className="w-fit !shadow-none"
            Icon={MinusIcon}
            onClick={handleDecrease}
          />
        )}

        <input
          {...(props as JSX.IntrinsicElements['input'])}
          className={sharedClassName}
          type={type !== 'search' && type !== 'url' ? type : 'text'}
          ref={ref as ForwardedRef<HTMLInputElement>}
          {...(type === 'url' && {
            title: 'URL',
            pattern:
              '^(http://www.|https://www.|http://|https://)?[a-z0-9]+([-.]{1}[a-z0-9]+)*.[a-z]{2,5}(:[0-9]{1,5})?(/.*)?$',
          })}
          {...(limit && { maxLength: limit })}
        />

        {type === 'number' && numberInputVariant === 'buttonControls' && (
          <Button
            className="w-fit !shadow-none"
            Icon={PlusIcon}
            onClick={handleIncrease}
          />
        )}

        {limit && (
          <Text
            className="absolute right-4 top-1/2 -translate-y-1/2 transform bg-transparent pl-2"
            as="span"
            variant="faded"
            size="sm"
          >
            {inputLength} / {limit}
          </Text>
        )}

        {LeftIcon && (
          <LeftIcon
            className={clsx(
              'absolute left-4 top-1/2 h-5 w-5 -translate-y-1/2 transform',
              {
                'text-grey peer-focus:peer-placeholder-shown:text-blue-light':
                  type === 'search',
              },
              leftIconClassName,
            )}
          />
        )}

        {RightIcon && rightIconClickHandler && (
          <button type="button" onClick={rightIconClickHandler}>
            <RightIcon
              className={clsx(
                'absolute right-4 top-1/2 h-5 w-5 -translate-y-1/2 transform',
                {
                  'invisible group-focus-within:visible peer-placeholder-shown:hidden':
                    type === 'search',
                },
                rightIconClassName,
              )}
            />
          </button>
        )}

        {RightIcon && !rightIconClickHandler && (
          <RightIcon
            className={clsx(
              'absolute right-4 top-1/2 h-5 w-5 -translate-y-1/2 transform',
              rightIconClassName,
            )}
          />
        )}

        {type === 'search' && (
          <button
            type="submit"
            className={clsx(
              'invisible absolute right-4 top-1/2 h-5 w-5 -translate-y-1/2 transform group-focus-within:visible peer-placeholder-shown:hidden',
              rightIconClassName,
            )}
          >
            <ArrowRightCircleIcon />
          </button>
        )}

        {type === 'search' && (
          <button
            type="button"
            className="absolute right-4 top-1/2 h-5 w-5 -translate-y-1/2 transform text-secondary-300 group-focus-within:hidden peer-placeholder-shown:hidden"
            onClick={(e) => {
              const input = document.getElementById(
                props.id as string,
              ) as HTMLInputElement;

              if (input) {
                input.value = '';
                onClearSearch && onClearSearch();
                if (props.defaultValue !== undefined) {
                  submit(e.currentTarget);
                }
              }
            }}
          >
            <XCircleIcon />
          </button>
        )}
      </div>
    );
  },
);

ValidatedInput.displayName = 'ValidatedInput';

type HiddenInputProps<T extends string> = {
  name: T;
  value?: string;
  type?: HTMLInputElement['type'];
};

export function HiddenInput<T extends string>({
  name,
  value,
  type,
}: HiddenInputProps<T>) {
  const { getInputProps } = useField(name);

  return <input {...getInputProps({ id: name, value, type })} hidden />;
}
