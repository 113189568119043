import React from 'react';

import clsx from 'clsx';

const fontWeight = {
  normal: 'font-normal',
  medium: 'font-medium',
  semibold: 'font-semibold',
  bold: 'font-bold',
};

const fontSize = {
  xl: 'text-[2.25rem]',
  'ls-xl': 'text-[25px]',
  lg: 'text-[19px]',
  l: 'text-[1.875rem]',
  md: 'text-[16px]',
  base: 'text-sm',
  normal: 'text-base',
  sm: 'text-xs',
  xs: 'text-[0.625rem]',
};

const variants = {
  heading: 'text-neutral-600',
  base: 'text-neutral-450',
  faded: 'text-neutral-400',
  white: 'text-neutral-50',
};

export type TextProps = {
  variant?: keyof typeof variants;
  size?: keyof typeof fontSize;
  weight?: keyof typeof fontWeight;
  as?: React.ElementType;
  className?: string;
  id?: string;
} & JSX.IntrinsicElements['p'];

/**
 * @deprecated This component is deprecated. Use eg: <p className="text-base"> instead.
 */
const Text = ({
  variant = 'base',
  size = 'base',
  weight = 'normal',
  as,
  className,
  ...rest
}: TextProps) => {
  const Tag = as || 'p';
  return (
    <Tag
      className={clsx(
        fontSize[size],
        variants[variant],
        fontWeight[weight],
        className,
      )}
      {...rest}
    />
  );
};

const H1 = ({ variant = 'heading', ...props }: TextProps) => (
  <Text {...props} size="xl" weight="semibold" as="h1" variant={variant} />
);

const H2 = ({ variant = 'heading', ...props }: TextProps) => (
  <Text {...props} size="lg" weight="semibold" as="h2" variant={variant} />
);

const H3 = (props: TextProps) => <Text {...props} size="md" as="h3" />;

const H4 = (props: TextProps) => <Text {...props} size="base" as="h4" />;

const H5 = (props: TextProps) => <Text {...props} size="sm" as="h5" />;

const H6 = (props: TextProps) => <Text {...props} size="xs" as="h6" />;

export { H1, H2, H3, H4, H5, H6, Text };
