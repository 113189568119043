import clsx from 'clsx';

import Card from './Card';

type PageFiltersProps = {
  className?: string;
  children: React.ReactNode;
};
const PageFilters = ({ children, className }: PageFiltersProps) => (
  <Card
    className={clsx(
      'flex items-center justify-between !bg-neutral-50 p-4',
      className,
    )}
  >
    {children}
  </Card>
);

export default PageFilters;
