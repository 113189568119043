import { BorderCard, H3 } from '~/components';

export type TitledBorderCardProps = {
  title: string;
  children: React.ReactNode;
};

const TitledBorderCard = ({ title, children }: TitledBorderCardProps) => (
    <BorderCard>
      <H3 className="mb-6">{title}</H3>
      {children}
    </BorderCard>
  );
export default TitledBorderCard;
