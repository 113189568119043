import { Link } from '@remix-run/react';

import clsx from 'clsx';

import { Button } from '~/components/ui/button';

type AdBannerProps = {
  selected?: boolean;
  className?: string;
  title?: string;
  description?: string;
  iconClassName?: string;
  textClassName?: string;
  link: string;
  buttonLabel: string;
};

const AdBanner = ({
  selected = false,
  link,
  className,
  title,
  description,
  buttonLabel,
}: AdBannerProps) => (
  <div
    className={clsx(
      'flex rounded-2xl bg-neutral-0 p-1 shadow-md transition-all hover:translate-y-[-2px]',
      className,
      {
        'bg-neutral-150': selected,
      },
    )}
  >
    <Link to={link} className="h-full w-full">
      <div className="flex h-full w-full flex-col items-center justify-between gap-6 rounded-xl bg-gradient-to-tr from-neutral-0 to-[#F5F0FF] px-10 py-10 xl:flex-row xl:gap-6">
        <div className="flex w-full flex-col gap-1">
          <p className="text-base font-semibold !leading-[170%]">{title}</p>
          <p className="text-sm !leading-[170%] text-neutral-450">
            {description}
          </p>
        </div>

        <div className="flex w-full flex-col gap-2 xl:w-1/2 xl:items-end">
          <Button className="w-full sm:w-fit">{buttonLabel}</Button>
        </div>
      </div>
    </Link>
  </div>
);

export default AdBanner;
