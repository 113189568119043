import type { TextProps } from '~/components';
import { Button, Card, Text } from '~/components';

import arrowImageURL from '/public/arrow.svg';
import installExtensionImageURL from '/public/employee/instructions/install-extension.png';
import loginExtensionImageUrl from '/public/employee/instructions/login-extension.png';
import openExtensionImageURL from '/public/employee/instructions/open-extension.png';

const ExtensionInstructions = () => (
  <div className="flex h-full flex-col justify-center gap-12 p-12">
    <InstructionCard
      title="Get Chrome extension to get Raffle"
      icon={installExtensionImageURL}
      arrow
    >
      <InstructionText>
        To install raffle Customer Service press the button {'"'}Get extension
        {'"'}. <br />
        This is a direct link to the Google Chrome Web Store. <br />
        <br />
        Then, press{' '}
        <InstructionHighlightText>
          {'"'}Add to Chrome{'"'}
        </InstructionHighlightText>
        .
      </InstructionText>
      <a
        href="https://chrome.google.com/webstore/detail/raffle-copilot/hmoadngnfhepbhndkkophlmndicacebi?hl=en&authuser=0"
        rel="noreferrer"
        target="_blank"
      >
        <Button variant="primary">Get extension</Button>
      </a>
    </InstructionCard>

    <InstructionCard
      title="Get raffle Extension"
      icon={openExtensionImageURL}
      arrow
    >
      <InstructionText>
        In the top right corner of your browser, there is a Puzzle icon. <br />
        Click the Puzzle icon and click the {'"'}pin{'"'} next to {'"'}
        raffle Search{'"'}.
      </InstructionText>
    </InstructionCard>

    <InstructionCard
      title="Login to raffle Extension"
      icon={loginExtensionImageUrl}
    >
      <InstructionText>
        Click on the raffle Search and sign in. <br />
        <br />
        <InstructionHighlightText>raffle</InstructionHighlightText> is now
        activated to your knowledge so that you can find anything, anywhere, at
        any time in those knowledge bases.
        <br />
      </InstructionText>
    </InstructionCard>
  </div>
);

export default ExtensionInstructions;

export const InstructionText = (props: TextProps) => (
  <Text className="leading-6 !text-neutral-500" {...props} />
);

export const InstructionHighlightText = (props: TextProps) => (
  <InstructionText variant="base" as="span" weight="semibold" {...props} />
);

type InstructionCardProps = {
  title: string;
  icon: string;
  arrow?: boolean;
  children: React.ReactNode;
};

const InstructionCard = ({
  title,
  icon,
  arrow = false,
  children,
}: InstructionCardProps) => (
  <div className="relative w-full 2xl:w-11/12">
    <Card className="flex items-center">
      <div className="flex w-1/3 shrink-0 justify-center">
        <img src={icon} className="w-1/2" alt="instructions" />
      </div>

      <div className="flex w-2/3 flex-col gap-4 p-6">
        <Text size="md" variant="heading" weight="semibold">
          {title}
        </Text>

        {children}
      </div>
    </Card>

    {arrow && (
      <img
        src={arrowImageURL}
        className="absolute left-full top-full ml-8 -mt-8 hidden w-12 2xl:block"
        alt="arrow"
      />
    )}
  </div>
);
