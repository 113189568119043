import { toast, ToastBar, Toaster } from 'react-hot-toast';

import { XMarkIcon } from '@heroicons/react/24/outline';

import Button from './Button';

export const ToastComponent = () => (
  <Toaster
    toastOptions={{
      success: {
        className: '',
        iconTheme: {
          primary: '#46B984',
          secondary: '#F0F3F7',
        },
      },
      error: {
        className: '',
        iconTheme: {
          primary: '#FF385C',
          secondary: '#F0F3F7',
        },
      },
      style: {
        border: '1px solid rgba(51, 51, 60, .5)',
        background: 'rgba(26, 30, 43, 0.7)',
        backdropFilter: 'blur(35px)',
        boxShadow:
          '0 0 80px rgba(0, 0, 0, 0.25), white 0px 15px 20px -2em inset',
        color: '#EDF1F8',
        borderRadius: '6px',
        padding: '0',
        paddingLeft: '16px',
        fontFamily: 'Inter',
        fontSize: '14px',
        fontWeight: '400',
        cursor: 'default',
        overflow: 'hidden',
      },
    }}
  >
    {(t) => (
      <ToastBar toast={t}>
        {({ icon, message }) => (
          <>
            {icon}
            {message}
            {t.type !== 'loading' && (
              <Button
                Icon={XMarkIcon}
                variant="icon"
                className="!ml-2 !h-full !rounded-none !border-b-0 !border-l !border-r-0 !border-t-0 !border-l-neutral-550 !border-opacity-50 !bg-neutral-600 !bg-opacity-30 !p-4 !text-xs leading-normal !text-neutral-50 hover:!bg-neutral-500 hover:!bg-opacity-80 hover:!text-neutral-50"
                onClick={() => toast.dismiss(t.id)}
              />
            )}
          </>
        )}
      </ToastBar>
    )}
  </Toaster>
);
