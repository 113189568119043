import { LockClosedIcon, LockOpenIcon } from '@heroicons/react/24/solid';

import type { Instance } from '@raffle-ai/types-search-frontend';

import type { ToolType, Source } from '~/models';

import SourceCard from '~/routes/_app.tools_.$toolId.source/view/SourceCard';

import Chip from '../Chip';
import { FormStepTitle } from '../FormStepTitle';
import RichToggle from '../RichToggle';

type ScopeSectionProps = {
  scope: Instance['scope'];
  toolType: ToolType;
  handleScopeChange: (scope: string) => void;
};

export const ScopeSection = ({
  scope,
  toolType,
  handleScopeChange,
}: ScopeSectionProps) => (
  <div className="flex flex-col gap-5">
    <FormStepTitle
      title="Privacy Settings"
      description="Select whether this User Interface is to be used internally (e.g. at your workplace) or publicly (e.g. on your website)."
      titleClassName="!text-base leading-normal"
      className="!mt-0"
    />

    <RichToggle
      falseOption={{
        label: 'Internal use',
        value: 'internal',
        Icon: LockClosedIcon,
      }}
      trueOption={{
        label: 'Public use',
        value: 'external',
        Icon: LockOpenIcon,
      }}
      value={scope || undefined}
      defaultValue={'external'}
      onClick={handleScopeChange}
      disabled={toolType === 'desktop' || toolType === 'extension'}
    />
  </div>
);

type SourceSelectionSectionProps = {
  error: string;
  selectedSources: Array<Source['id']>;
  sources: Source[];
  handleSelectSource: (sourceId: Source['id']) => void;
  scope: Instance['scope'];
};

export const SourceSelectionSection = ({
  error,
  selectedSources,
  sources,
  handleSelectSource,
  scope,
}: SourceSelectionSectionProps) => (
  <div className="flex flex-col gap-5">
    <FormStepTitle
      title="Indexes"
      description="Select which sources should be active in this User Interface"
      titleAdditionalContent={
        <Chip children={`${sources.length} data sources`} className="ml-2" />
      }
      titleClassName="!text-base leading-normal"
      className="!mt-0"
    />

    <div className="flex flex-col rounded-md border border-neutral-250">
      {sources.map((source) => (
        <SourceCard
          key={source.id}
          source={source}
          onToggle={() => handleSelectSource(source.id)}
          selected={selectedSources.includes(source.id)}
          disabled={source.internal && scope === 'external'}
        />
      ))}
    </div>
  </div>
);
