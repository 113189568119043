import clsx from 'clsx';

export type TableBottombarProps = {
  className?: string;
  children: React.ReactNode;
};
export const TableBottombar = ({
  children,
  className,
}: TableBottombarProps) => (
  <div
    className={clsx(
      'flex items-center justify-between rounded-md rounded-t-none border border-t-0 border-neutral-250 bg-neutral-0 py-3 px-4',
      className,
    )}
  >
    {children}
  </div>
);
