import type { Source } from '~/models';

import { sourceTypeDict } from './constants';

export function hasCredentials(
  type: Source['type'],
  configuration?: Source['configuration'],
): boolean {
  if (
    type === 'instant_answers' ||
    type === 'web_scrape' ||
    type === 'manual_upload'
  ) {
    return true;
  }

  if (configuration === undefined) {
    return false;
  }

  return sourceTypeDict[type]?.fields
    .filter(({ type }) => type === 'credentials')
    .every(({ name }) => {
      const cleanName = name.replace('[]', '');
      return (
        // @ts-expect-error
        !!configuration[cleanName]
      );
    });
}

export function getCleanHostname(domain: string) {
  let sourceUrl = domain.replace('http://', '');

  if (!sourceUrl.includes('https://')) {
    sourceUrl = `https://${sourceUrl}`;
  }

  let { hostname } = new URL(sourceUrl);

  return hostname.replace('www.', '');
}

export function isSyncing(sync_status: Source['sync_status']) {
  return (
    sync_status === 'pending_sync' ||
    sync_status === 'syncing' ||
    sync_status === 'pending_postprocess' ||
    sync_status === 'postprocessing'
  );
}

export function isSyncSuccessful(sync_status: Source['sync_status']) {
  return sync_status === 'succeeded' || sync_status === 'idle';
}
