import clsx from 'clsx';

import type { ButtonProps } from './Button';

import type { HeroIcon } from '~/types';

import Button from './Button';
import DynamicTooltip from './DynamicTooltip';

type ButtonTooltipProps = {
  children: React.ReactNode;
  className?: string;
  Icon: HeroIcon;
  type?: 'submit' | 'button';
  disabled?: boolean;
  variant?: ButtonProps['variant'];
  size?: ButtonProps['size'];
  onClick?: ButtonProps['onClick'];
};

const ButtonTooltip = ({
  children,
  className,
  size,
  onClick,
  Icon,
  type = 'submit',
  disabled = false,
  variant = 'icon',
}: ButtonTooltipProps) => (
  <DynamicTooltip
    offset={{ x: -20, y: -40 }}
    content={
      <div className="flex flex-col">
        <p className="text-[13px] font-medium !text-neutral-550">{children}</p>
      </div>
    }
  >
    <Button
      className={clsx(className)}
      Icon={Icon}
      type={type}
      disabled={disabled}
      variant={variant}
      size={size}
      onClick={onClick}
    />
  </DynamicTooltip>
);

export default ButtonTooltip;
