import { useCallback, useEffect, useMemo, useState } from 'react';

export type Change = 'add' | 'remove' | 'update' | undefined;

type ID = string | number;
interface Identifiable {
  id: ID;
  change?: Change;
}

export default function useDiffableItems<T extends Identifiable>(
  initialItems: T[],
  isEqual: (a: T, b: T) => boolean,
  hasChange: (a: T) => boolean = (value) => !!value.change,
) {
  const [items, setItems] =
    useState<Array<T & { change?: Change }>>(initialItems);

  useEffect(() => {
    setItems(initialItems);
  }, [setItems, initialItems]);

  const add = useCallback(
    (newItem: T) => {
      setItems((items) => [...items, { ...newItem, change: 'add' }]);
    },
    [setItems],
  );

  // Will remove items that have `change == "add"`
  // and set `change = "remove"` for other items.
  const remove = useCallback(
    (id: ID) => {
      setItems((items) =>
        items
          .filter((item) => !(item.id === id && item.change === 'add'))
          .map((item) => {
            if (item.id !== id) return item;
            return { ...item, change: 'remove' };
          }),
      );
    },
    [setItems],
  );

  // Will update items and keep `change == "add"` if item was added
  // otherwise it will set `change = "update"` if any changes were made.
  const update = useCallback(
    (update: T) => {
      setItems((items) =>
        items.map((item) => {
          if (item.id !== update.id) return item;
          const initialItem = initialItems.find((i) => i.id === update.id);

          let updateChange: Change = undefined;
          if (item.change === 'add') {
            updateChange = 'add';
          } else if (initialItem && !isEqual(initialItem, update)) {
            updateChange = 'update';
          }
          return { ...update, change: updateChange };
        }),
      );
    },
    [setItems, isEqual, initialItems],
  );

  const hasChanges = useMemo(() => items.some(hasChange), [hasChange, items]);

  return { items, add, remove, update, hasChanges };
}
