import { useEffect, useState } from 'react';

import type { Instance } from '~/models';

import { PreviewIframe } from '~/components';

const ToolView = ({
  scriptUrl,
  currentInstance,
  disableSupport,
  shouldReload,
}: {
  scriptUrl: string;
  currentInstance: Instance;
  disableSupport?: boolean;
  shouldReload?: boolean;
}) => {
  const [canLoad, setCanLoad] = useState(false);

  useEffect(() => {
    const timer = setTimeout(() => setCanLoad(true), 100);

    return () => {
      clearTimeout(timer);
    };
  }, [currentInstance.uid]);

  if (!canLoad) {
    return null;
  }

  const isChatOnly =
    //@ts-ignore
    currentInstance.configuration?.behaviour?.enable_search === false;

  return (
    <PreviewIframe
      enforceEmbedded={!isChatOnly}
      enforceDefaultConfig={currentInstance.type !== 'widget' && !isChatOnly}
      scriptUrl={scriptUrl}
      tool={currentInstance}
      customTargetWidth="98vw"
      disableSupport={disableSupport}
      shouldReload={shouldReload}
    />
  );
};

export default ToolView;
