import { Link, useNavigate } from '@remix-run/react';

import { Input } from '../Form';
import RichToggle from '../RichToggle';
import { CreateNewResourceButton } from '../CardButton';

type PageFiltersSettigsProps = {
  route: 'users' | 'pending-invites';
  query: string;
  onChangeQuery: (query: string) => void;
};

const PageFiltersSettings = ({
  route,
  query,
  onChangeQuery,
}: PageFiltersSettigsProps) => {
  const navigate = useNavigate();

  const isUsersRoute = route === 'users';

  return (
    <div className="flex flex-col pb-3">
      <div className="flex flex-col pb-6">
        <Link to="invite" className="w-fit">
          <CreateNewResourceButton
            title="Invite new user"
            subtitle="Expand your team"
          />
        </Link>
      </div>
      <div className="-mx-6 border-b border-neutral-200" />
      <div className="flex flex-1 justify-between pt-6">
        <Input
          id="search-users-table"
          type="search"
          containerClassName="w-1/3"
          value={query}
          onChange={(e) => onChangeQuery(e.target.value)}
          onClearSearch={() => onChangeQuery('')}
          placeholder={
            isUsersRoute
              ? 'Filter by user, roles, teams or last active'
              : 'Filter by email, sent or expiration date'
          }
        />

        <RichToggle
          wrapperClassName="w-96"
          falseOption={{ label: 'Users', value: 'users' }}
          trueOption={{ label: 'Pending Invites', value: 'pending-invites' }}
          defaultValue={route}
          onClick={() =>
            navigate(`/settings/users${isUsersRoute ? '/pending-invites' : ''}`)
          }
        />
      </div>
    </div>
  );
};

export default PageFiltersSettings;
