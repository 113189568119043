import { useState } from 'react';

import {
  Link,
  Outlet,
  useLoaderData,
  useLocation,
  useNavigate,
  useOutletContext,
} from '@remix-run/react';

import type { AnswersResponse } from '~/models';

import type {
  AnswersOutletData,
  AnswersTableProps,
  FileUpload,
} from '~/components';
import {
  FILE_STATUS,
  AnswersTable,
  CreateNewResourceButton,
} from '~/components';

export type LoaderData = {
  data: AnswersTableProps['data'];
  pagination: AnswersResponse['pagination'];
};

export const PAGINATION_FETCH_LIMIT = 50;

export const View = () => {
  const { data, pagination } = useLoaderData<LoaderData>();
  const { pathname } = useLocation();
  const navigate = useNavigate();

  const [files, setFiles] = useState<FileUpload[]>([]);

  const { source } = useOutletContext<AnswersOutletData>();

  const isBackstage = pathname.includes('backstage');

  const handleDrop = (acceptedFiles: File[]) => {
    setFiles((prev) => [
      ...prev,
      ...acceptedFiles.map((file) => ({ file, status: FILE_STATUS.INITIAL })),
    ]);
    navigate('upload');
  };

  return (
    <>
      <div className="flex flex-col">
        {(source.type === 'manual_upload' ||
          source.type === 'instant_answers') && (
          <>
            <div className="flex pb-6">
              <div className="border-b border-neutral-250" />
              <Link
                className="w-fit"
                to={source.type === 'instant_answers' ? 'new' : 'upload'}
              >
                <CreateNewResourceButton
                  title={
                    source.type === 'instant_answers'
                      ? 'Create new answer'
                      : 'Upload file'
                  }
                  subtitle={
                    source.type === 'instant_answers'
                      ? 'Add another instant answer to this index'
                      : 'Choose a file from your system to upload'
                  }
                />
              </Link>
            </div>

            <div className="-mx-6 mb-3 border-b border-neutral-200" />
          </>
        )}
      </div>
      <AnswersTable
        data={data}
        isBackstage={isBackstage}
        pagination={pagination}
        handleDrop={handleDrop}
      />

      <Outlet context={{ files }} />
    </>
  );
};
