import React, { forwardRef } from 'react';

import clsx from 'clsx';
import { motion } from 'framer-motion';
import { ClientOnly } from 'remix-utils/client-only';
import PopupComponent from 'reactjs-popup';

import type { PopupProps } from 'reactjs-popup/dist/types';
import type { ButtonProps } from './Button';

import { fadeInScaleUp } from '~/utils';

import Button from './Button';

type PopupMenuProps = {
  children: React.ReactNode;
  className?: string;
  origin?: 'top-right' | 'top-left' | 'bottom-right' | 'bottom-left';
};

export const PopupMenu = ({
  children,
  className,
  origin = 'top-right',
}: PopupMenuProps) => (
  <motion.div
    {...fadeInScaleUp}
    className={clsx('rounded-lg bg-neutral-50 shadow', className, {
      'origin-top-right': origin === 'top-right',
      'origin-top-left': origin === 'top-left',
      'origin-bottom-right': origin === 'bottom-right',
      'origin-bottom-left': origin === 'bottom-left',
    })}
  >
    {children}
  </motion.div>
);

export const PopupMenuItem = ({
  children,
  className,
  Icon,
  ...rest
}: ButtonProps) => (
  <Button
    // @ts-ignore
    ref={undefined}
    className={clsx(
      'w-full !justify-start !bg-neutral-50 font-medium !shadow-none hover:bg-neutral-200',
      className,
    )}
    Icon={Icon}
    {...rest}
  >
    {children}
  </Button>
);

type ClientPopUpProps = PopupProps & { fallback?: JSX.Element };

export const Popup = forwardRef<ClientPopUpProps, ClientPopUpProps>(
  ({ fallback, ...props }: ClientPopUpProps, ref) => (
    <ClientOnly fallback={fallback}>
      {/* @ts-expect-error ref */}
      {() => <PopupComponent {...props} ref={ref} />}
    </ClientOnly>
  ),
);

Popup.displayName = 'Popup';
