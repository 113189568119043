import { forwardRef, useMemo, useState } from 'react';
import type { ForwardedRef } from 'react';

import { useSubmit } from '@remix-run/react';

import clsx from 'clsx';

import {
  ArrowRightCircleIcon,
  MagnifyingGlassIcon,
  XCircleIcon,
} from '@heroicons/react/24/solid';

import type { HeroIcon } from '~/types';

import { Text } from '../_legacy/Typography';
import { Button } from '../ui/button';

export type InputProps = {
  LeftIcon?: HeroIcon;
  RightIcon?: HeroIcon;
  leftIconClassName?: string;
  rightIconClassName?: string;
  rightIconClickHandler?: () => void;
  containerClassName?: string;
  onClearSearch?: () => void;
  limit?: number;
  inputStyle?: string;
  preventSubmitOnClear?: boolean;
  unit?: string;
  handleReset?: () => void;
} & JSX.IntrinsicElements['input'];

export const inputSharedStaticClassName =
  'peer px-4 py-3 w-full text-dark placeholder:font-normal focus:caret-blue-light text-sm leading-normal font-normal rounded-md outline-none disabled:cursor-not-allowed duration-250 border border-neutral-250 bg-neutral-0 shadow-neutral-150 transition-all placeholder:text-neutral-450 hover:border-neutral-250 hover:bg-neutral-0 hover:shadow focus:border-neutral-300 focus:bg-neutral-0 focus:shadow-solid active:border-neutral-250 active:shadow-none';
export const inputPlaceholderClasses =
  'placeholder:text-sm leading-normal placeholder:text-neutral-450 placeholder:font-normal';

const Input = forwardRef<HTMLInputElement | HTMLTextAreaElement, InputProps>(
  (
    {
      className,
      type,
      LeftIcon,
      RightIcon,
      leftIconClassName,
      rightIconClassName,
      rightIconClickHandler,
      containerClassName,
      onClearSearch,
      limit,
      inputStyle,
      preventSubmitOnClear = false,
      ...props
    }: InputProps,
    ref,
  ) => {
    const submit = useSubmit();

    const [range, setRange] = useState(props.defaultValue);

    if (type === 'search') {
      LeftIcon = MagnifyingGlassIcon;
    }

    const inputLength = useMemo(() => {
      if (ref != null && typeof ref !== 'function' && ref.current) {
        return ref.current.value.length;
      }
      if (
        typeof props.defaultValue !== 'undefined' &&
        props.defaultValue !== null
      ) {
        return props.defaultValue?.toString().length;
      }

      if (typeof props.value !== 'undefined') {
        return props.value.toString().length;
      }

      return 0;
    }, [ref, props.defaultValue, props.value]);

    const sharedClassName = clsx(
      inputSharedStaticClassName,
      inputPlaceholderClasses,
      { 'pl-12': !!LeftIcon },
      { 'pr-12': !!RightIcon || type === 'search' },
      className,
    );

    if (type === 'textarea') {
      return (
        <div
          className={clsx(
            'group relative text-grey focus-within:text-accent-purple-400',
            containerClassName,
          )}
        >
          <textarea
            {...(props as JSX.IntrinsicElements['textarea'])}
            className={clsx('min-h-[5rem]', sharedClassName)}
            ref={ref as ForwardedRef<HTMLTextAreaElement>}
            {...(limit && { maxLength: limit })}
          />

          {limit && (
            <Text
              className="absolute bottom-2 right-4 -translate-y-1/2 transform bg-transparent"
              as="span"
              variant="faded"
              size="sm"
            >
              {inputLength} / {limit}
            </Text>
          )}
        </div>
      );
    }

    if (type === 'range') {
      const { handleReset, defaultValue, ...rest } = props;
      return (
        <div className="flex items-center gap-4">
          <input
            type="range"
            {...rest}
            value={range}
            className={clsx(
              '[&::-webkit-slider-thumb]:!bg-neutral-600',
              inputStyle,
            )}
            onChange={(e) => setRange(parseInt(e.target.value))}
          />
          <div className="item-center flex gap-2">
            <span className="flex w-12 items-center justify-center rounded-md bg-neutral-600 text-[13px] text-white">
              {range}
              {props.unit}
            </span>

            <Button
              size="sm"
              onClick={() => {
                setRange(props.defaultValue);
                handleReset && handleReset();
              }}
              variant="secondary"
              className="text-[13px]"
              type="button"
            >
              Reset
            </Button>
          </div>
        </div>
      );
    }

    return (
      <div
        className={clsx(
          'group relative text-grey focus-within:text-accent-purple-400',
          containerClassName,
        )}
      >
        <input
          {...(props as JSX.IntrinsicElements['input'])}
          className={clsx(
            'placeholder:max-w-[85%] placeholder:text-ellipsis',
            sharedClassName,
          )}
          type={type !== 'search' && type !== 'url' ? type : 'text'}
          ref={ref as ForwardedRef<HTMLInputElement>}
          {...(type === 'url' && {
            title: 'URL',
            pattern:
              '^(http://www.|https://www.|http://|https://)?[a-z0-9]+([-.]{1}[a-z0-9]+)*.[a-z]{2,5}(:[0-9]{1,5})?(/.*)?$',
          })}
          {...(limit && { maxLength: limit })}
        />

        {limit && (
          <Text
            className="absolute right-4 top-1/2 -translate-y-1/2 transform bg-transparent"
            as="span"
            variant="faded"
            size="sm"
          >
            {inputLength} / {limit}
          </Text>
        )}

        {LeftIcon && (
          <LeftIcon
            className={clsx(
              'absolute left-4 top-1/2 h-4 w-4 -translate-y-1/2 transform',
              {
                'text-neutral-450 peer-focus:peer-placeholder-shown:text-accent-purple-400':
                  type === 'search',
              },
              leftIconClassName,
            )}
          />
        )}

        {RightIcon && rightIconClickHandler && (
          <button type="button" onClick={rightIconClickHandler}>
            <RightIcon
              className={clsx(
                'absolute right-4 top-1/2 h-5 w-5 -translate-y-1/2 transform',
                {
                  'invisible group-focus-within:visible peer-placeholder-shown:hidden':
                    type === 'search',
                },
                rightIconClassName,
              )}
            />
          </button>
        )}

        {RightIcon && !rightIconClickHandler && (
          <RightIcon
            className={clsx(
              'absolute right-4 top-1/2 h-5 w-5 -translate-y-1/2 transform',
              rightIconClassName,
            )}
          />
        )}

        {type === 'search' && (
          <button
            type="submit"
            onClick={() => {
              const input = document.getElementById(
                props.id as string,
              ) as HTMLInputElement;

              input.blur();
            }}
            className={clsx(
              'invisible absolute right-4 top-1/2 h-5 w-5 -translate-y-1/2 transform group-focus-within:visible peer-placeholder-shown:hidden',
              rightIconClassName,
            )}
          >
            <ArrowRightCircleIcon />
          </button>
        )}

        {type === 'search' && (
          <button
            type="button"
            className={clsx(
              'absolute right-4 top-1/2 h-5 w-5 -translate-y-1/2 transform text-secondary-300 group-focus-within:hidden peer-placeholder-shown:hidden',
              rightIconClassName,
            )}
            onClick={(e) => {
              const input = document.getElementById(
                props.id as string,
              ) as HTMLInputElement;

              if (input) {
                input.value = '';
                onClearSearch && onClearSearch();
                if (!preventSubmitOnClear && props.defaultValue !== undefined) {
                  submit(e.currentTarget);
                }
              }
            }}
          >
            <XCircleIcon />
          </button>
        )}
      </div>
    );
  },
);

Input.displayName = 'Input';

export default Input;
