import type { ErrorResponse } from '@remix-run/react';

import { Text } from './_legacy/Typography';

export const CatchAllError = ({ error }: { error: ErrorResponse }) => (
  <div className="m-auto flex h-full items-center justify-center">
    <Text size="lg" weight="semibold">
      Something went wrong{' '}
    </Text>
  </div>
);
