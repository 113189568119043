import { useEffect } from 'react';

import { useQuill } from 'react-quilljs';

export type InstantAnswerEditorProps = {
  initialValue: string;
  onChange: (value: string) => void;
  isEditDisabled?: boolean;
};

export default function InstantAnswerEditor({
  initialValue,
  onChange,
  isEditDisabled = false,
}: InstantAnswerEditorProps) {
  const theme = 'snow';

  const modules = {
    toolbar: [
      [{ header: '1' }, { header: '2' }],
      ['bold', 'italic', 'underline'],
      [{ list: 'ordered' }, { list: 'bullet' }],
      ['link', 'image', 'video'],
    ],
  };
  const placeholder = 'Write an answer ... ';
  const formats = [
    'bold',
    'italic',
    'underline',
    'list',
    'indent',
    'link',
    'image',
    'video',
    'header',
  ];

  const { quill, quillRef } = useQuill({
    theme,
    modules,
    formats,
    placeholder,
  });

  useEffect(() => {
    if (quill && quillRef) {
      const changeHandler = (_: unknown, __: unknown, source: string) => {
        if (source === 'user') {
          onChange(quillRef.current.firstChild.innerHTML);
        }
      };

      quill.on('text-change', changeHandler);

      if (isEditDisabled) {
        quill.disable();
      }
      return () => {
        quill.off('text-change', changeHandler);
      };
    }
  }, [quill, quillRef, onChange, isEditDisabled]);

  useEffect(() => {
    if (quill && initialValue) {
      quill.setText('');
      quill.clipboard.dangerouslyPasteHTML(initialValue);
    }
  }, [quill, quillRef, initialValue]);

  return (
    <div className="flex w-full flex-grow flex-col overflow-hidden rounded-md bg-neutral-50 p-2">
      <div
        className="answer-content max-h-[60vh] flex-grow overflow-y-auto overflow-x-hidden p-2"
        ref={quillRef}
      />
    </div>
  );
}
