import ReactSelect from 'react-select';
import { ClientOnly } from 'remix-utils/client-only';
import { tailwindConfig } from '@raffle-ai/design-system';

import type { GroupBase, Props } from 'react-select';

import { ValidatedInput } from '../ValidatedForm';

function Select<
  Option,
  IsMulti extends boolean = false,
  Group extends GroupBase<Option> = GroupBase<Option>,
>(props: Props<Option, IsMulti, Group>) {
  return (
    <ClientOnly
      fallback={
        <ValidatedInput
          className="!py-2.5 !pl-[10px]"
          placeholder="Select..."
        />
      }
    >
      {() => (
        <ReactSelect
          className="duration-250 bg-neutral-300 !text-sm !font-normal leading-normal !text-dark outline-none transition-all placeholder:text-neutral-400 focus:border-neutral-300 focus:bg-neutral-0 focus:shadow-solid active:border-neutral-250 active:shadow-none"
          components={{ IndicatorSeparator: undefined }}
          styles={{
            control: () => ({
              backgroundColor: tailwindConfig.theme.colors['neutral']['100'],
              display: 'flex',
              borderRadius: '8px',
              border: '1px solid',
              borderColor: tailwindConfig.theme.colors['neutral']['250'],
              height: '42px',
              '&:hover': {
                backgroundColor: tailwindConfig.theme.colors['neutral']['0'],
                borderColor: tailwindConfig.theme.colors['neutral']['250'],
              },
            }),
            menu: (base) => ({
              ...base,
              minWidth: '200px',
            }),
          }}
          menuPosition="fixed"
          isSearchable={props.isSearchable ?? false}
          {...props}
        />
      )}
    </ClientOnly>
  );
}

export default Select;
