import React from 'react';

import { Link } from '@remix-run/react';

import type { ColumnDef } from '@tanstack/react-table';
import type { TableProps } from './Table';

import { Card } from '../Layout';
import { Text } from '../_legacy/Typography';
import Table from './Table';

const columns: Array<ColumnDef<TeamProps>> = [
  {
    id: 'team',
    accessorKey: 'name',
    header: () => (
      <Text variant="heading" weight="medium">
        Team
      </Text>
    ),
    cell: ({
      row: {
        original: { id, name, memberCount },
      },
    }) => (
      <Link to={`/settings/teams/${id}`}>
        <div className="flex flex-1 flex-col gap-1">
          <Text variant="heading">{name}</Text>
          <div>
            <Text variant="faded">
              {memberCount} {memberCount == 1 ? 'member' : 'members'}
            </Text>
          </div>
        </div>
      </Link>
    ),
  },
];

export type TeamProps = {
  id: number;
  name: string;
  memberCount: number;
};
type TeamsTableProps = Omit<TableProps<TeamProps>, 'columns'>;

const TeamsTable = ({ data, globalFilter }: TeamsTableProps) => (
  <Card className="z-0 overflow-auto !p-0">
    <Table
      data={data}
      columns={columns}
      className="border-collapse"
      headerClassName="bg-white-off sticky top-0 z-10"
      headerCellClassName="text-left"
      bodyClassName="divide-y divide-grey-light"
      bodyRowClassName="hover:bg-white-off"
      globalFilter={globalFilter}
    />
  </Card>
);

export default TeamsTable;
