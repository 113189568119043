import React from 'react';

import { motion } from 'framer-motion';

interface StaggeredTextProps {
  children: string;
  className?: string;
}

const letterAnimation = {
  hidden: { opacity: 0, y: 8, filter: 'blur(8px)' },
  visible: (i: number) => ({
    filter: 'blur(0px)',
    opacity: 1,
    y: 0,
    transition: {
      delay: 0.5 + i * 0.02,
      duration: 0.4,
      ease: 'easeOut',
    },
  }),
};

const StaggeredText: React.FC<StaggeredTextProps> = ({
  children,
  className,
}) => (
  <motion.div initial="hidden" animate="visible" className={className}>
    {children.split('').map((letter, index) => (
      <motion.span
        key={index}
        custom={index}
        variants={letterAnimation}
        style={{ display: 'inline-block' }}
      >
        {letter === ' ' ? '\u00A0' : letter} {/* Handle space */}
      </motion.span>
    ))}
  </motion.div>
);

export default StaggeredText;
