import { useCallback } from 'react';

import { Link, useFetcher, useLocation } from '@remix-run/react';

import clsx from 'clsx';

import type { FeatureName } from '~/models';
import type { HeroIcon } from '~/types';

import { useUser } from '~/providers';

import { RequestAccessButton } from './RequestAccessButton';
import { UnlockProductButton } from './UnlockProductButton';
import { Badge } from './ui/badge';
import { DataLoadingAnimation } from './LoadingSpinner';
import { Button } from './ui/button';

type PlaceholderContainerProps = {
  children: React.ReactNode;
  className?: string;
};
const PlaceholderContainer = ({
  children,
  className,
}: PlaceholderContainerProps) => (
  <div
    className={clsx(
      'flex flex-1 cursor-default flex-col items-center justify-center',
      className,
    )}
  >
    {children}
  </div>
);

type PlaceholdderProps = {
  title: string;
};

type LockedFeaturePlaceholderProps = PlaceholdderProps & {
  feature?: FeatureName;
  description?: string;
};

export const LockedFeaturePlaceholder = ({
  title,
  description,
  feature,
}: LockedFeaturePlaceholderProps) => {
  const { account } = useUser();

  const isFree = account.features.free_access?.amount === 1;

  return isFree ? (
    <PlaceholderContainer className="gap-4">
      <div className="flex flex-col gap-1">
        <p className="text-center text-sm font-semibold leading-normal text-neutral-600">
          Upgrade plan to unlock {title}
        </p>

        {description && (
          <p className="max-w-md text-center text-sm leading-normal text-neutral-450">
            {description}
          </p>
        )}
      </div>
      <UnlockProductButton />
    </PlaceholderContainer>
  ) : (
    <PlaceholderContainer>
      <p className="text-center text-sm font-medium leading-normal text-neutral-600">
        Unlock {title}
      </p>

      {description && (
        <p className="text-center text-sm leading-normal text-neutral-500">
          {description}
        </p>
      )}

      {feature && <RequestAccessButton className="mt-2" feature={feature} />}
    </PlaceholderContainer>
  );
};

export const NoDataPlaceholder = () => (
  <PlaceholderContainer>
    <div className="flex flex-col gap-1">
      <p className="text-center text-sm font-medium leading-normal text-neutral-600">
        No data for this report yet
      </p>

      <p className="text-center text-sm leading-normal text-neutral-500">
        We found no data with the selected filters.
      </p>
    </div>

    <div className="mt-3 flex gap-2">
      <Badge variant="solid">
        <div className="flex items-center gap-1.5">
          <div className="h-2 w-2 rounded-full border border-neutral-250 bg-accent-purple-300 p-1" />
          <p className="text-xs font-normal leading-normal text-neutral-600">
            Choose different User Interface
          </p>
        </div>
      </Badge>

      <Badge variant="solid">
        <div className="flex items-center gap-1.5">
          <div className="h-2 w-2 rounded-full border border-neutral-250 bg-accent-purple-300 p-1" />
          <p className="text-xs font-normal leading-normal text-neutral-600">
            Change the date
          </p>
        </div>
      </Badge>
    </div>
  </PlaceholderContainer>
);

export const FailedToLoadPlaceholder = ({ title }: { title?: string }) => (
  <PlaceholderContainer className="!p-2">
    <div className="flex flex-col gap-1">
      <p className="text-center text-sm font-medium leading-normal text-neutral-600">
        Could not load {title}
      </p>

      <p className="text-center text-sm leading-normal text-neutral-450">
        Please try again
      </p>
    </div>
  </PlaceholderContainer>
);

export const NoSupportChannelsPlaceholder = () => (
  <PlaceholderContainer>
    <div className="flex flex-col gap-1">
      <p className="text-center text-sm font-medium leading-normal text-neutral-600">
        No support channel is set up
      </p>

      <p className="text-center text-sm leading-normal text-neutral-450">
        The currently selected User Interfaces do not have any support channels
        enabled.
      </p>
    </div>

    <div className="mt-3 flex gap-2">
      <Badge variant="solid">
        <div className="flex items-center gap-1.5">
          <div className="h-2 w-2 rounded-full border border-neutral-250 bg-accent-purple-300 p-1" />
          <p className="text-xs font-normal leading-normal text-neutral-600">
            Choose different User Interface
          </p>
        </div>
      </Badge>

      <Badge variant="solid">
        <div className="flex items-center gap-1.5">
          <div className="h-2 w-2 rounded-full border border-neutral-250 bg-accent-purple-300 p-1" />
          <p className="text-xs font-normal leading-normal text-neutral-600">
            Activate support channels
          </p>
        </div>
      </Badge>
    </div>
  </PlaceholderContainer>
);

export const LoadingPlaceholder = () => (
  <PlaceholderContainer>
    <div className="l-auto r-auto t-auto b-auto relative mb-5 flex flex-1 flex-col items-center justify-center gap-2">
      <div className="absolute h-28 w-28 animate-[ping_1.5s_ease-in-out_infinite] rounded-full border border-neutral-150 bg-transparent" />
      <div className="absolute h-28 w-28 animate-[ping_1.5s_ease-in-out_0.5s_infinite] rounded-full border border-neutral-150 bg-transparent" />
      <div className="absolute h-28 w-28 animate-[ping_1.5s_ease-in-out_1s_infinite] rounded-full border border-neutral-150 bg-transparent" />
      <DataLoadingAnimation />
      <p className="mt-1 text-xs font-medium leading-normal text-neutral-550">
        Fetching data...
      </p>
    </div>
  </PlaceholderContainer>
);

type BlurredOverlayPlaceholderProps = {
  title?: string;
  Icon: HeroIcon;
  paragraphs: string[];
  docsUrl?: string;
  featureName?: FeatureName;
  featureLabel: string;
  type: 'request' | 'self_activate';
};

export const BlurredOverlayPlaceholder = ({
  Icon,
  title = 'Set it up in 5 minutes!',
  paragraphs,
  docsUrl,
  featureName,
  featureLabel,
  type,
}: BlurredOverlayPlaceholderProps) => {
  const fetcher = useFetcher();
  const { search } = useLocation();

  const {
    account: { features },
  } = useUser();

  const isFree = features.free_access?.amount !== 0;

  const handleRequestFeature = useCallback(() => {
    fetcher.submit(
      {
        feature: featureName?.toString() ?? null,
        toolUrlTab: featureName === 'raffle_chat' ? 'chat' : 'support',
      },
      {
        action: `/action/self-activate${search}`,
        method: 'POST',
      },
    );
  }, [fetcher, featureName, search]);

  return (
    <div className="absolute z-50 flex h-full w-full items-center justify-center shadow-[inset_0_4px_6px_0_rgb(0,0,0,0.05)] backdrop-blur-sm">
      <div className="flex max-w-[500px] flex-col overflow-hidden rounded-md border border-neutral-250 bg-neutral-0 shadow-md">
        <div className="flex gap-4 px-4 pt-4">
          {/* <div className="rounded-md bg-accent-blue-100 bg-opacity-10 p-2">
            {Icon ? <Icon className="h-4 w-4 text-accent-blue-300" /> : null}
          </div> */}

          <div className="flex flex-col justify-center">
            <p className="text-base font-semibold leading-normal text-neutral-600">
              Do you want to have {featureLabel}?
            </p>
            {/* <p className="text-sm font-medium leading-normal text-neutral-450">
              {title}{' '}
            </p> */}
          </div>
        </div>

        <div className="flex flex-col gap-2 px-4 pb-4 pt-2">
          {paragraphs.map((paragraph, index) => (
            <p key={index} className="text-sm leading-normal text-neutral-500">
              {paragraph}
            </p>
          ))}
        </div>

        <div className="flex justify-start p-4 pt-0">
          {type === 'request' && isFree ? (
            <Button variant="default" asChild>
              <Link to="/settings/plan">Get {featureLabel} today</Link>
            </Button>
          ) : (
            <Button variant="default" onClick={handleRequestFeature}>
              Set up {featureLabel}
            </Button>
          )}

          {/* {docsUrl && (
            <div className="flex items-center gap-3">
              <p className="text-xs font-medium leading-normal text-neutral-450">
                Using our API?
              </p>

              <Link to={docsUrl}>
                <Button className="flex gap-2" variant="secondary">
                  <DocumentTextIcon className="h-4 w-4" />
                  Docs
                </Button>
              </Link>
            </div>
          )} */}
        </div>
      </div>
    </div>
  );
};
