import toast from 'react-hot-toast';

type PressToCopyTextProps = {
  copyText?: string;
  toastMessage?: string;
  children: string | number;
};

export const PressToCopyText = ({
  copyText,
  children,
  toastMessage,
}: PressToCopyTextProps) => {
  const text = copyText ?? children?.toString();

  return (
    <button
      type="button"
      onClick={() =>
        navigator.clipboard
          .writeText(text)
          .then(() =>
            toast.success(`"${toastMessage ?? text}" copied to clipboard`),
          )
      }
    >
      <p className="text-left text-[13px]">{children}</p>
    </button>
  );
};
