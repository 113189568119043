import { useCallback, useMemo, useState } from 'react';

import { useSubmit } from '@remix-run/react';

import { ClientOnly } from 'remix-utils/client-only';
import { confirmAlert } from 'react-confirm-alert';

import {
  MinusCircleIcon,
  PlusIcon,
  ArrowDownOnSquareIcon,
  TrashIcon,
} from '@heroicons/react/24/solid';

import type { UserInfoProps } from './UserInfo';

import { alfaCompare } from '~/utils';

import { Modal, ModalHeader } from './Modal.client';
import { Field } from './Form';
import { Text } from './_legacy/Typography';
import Button from './Button';
import UserInfo from './UserInfo';
import {
  LegacyConfirmModal,
  CONFIRM_MODAL_OVERLAY_CLASSNAME,
  DeleteConfirmModal,
} from './_legacy/LegacyConfirmModal';

type User = UserInfoProps & { id: number };
type Team = {
  name: string;
  members: User[];
};

export type TeamModalProps = { users: User[]; team: Team; create?: boolean };

const TeamModal = ({ team, users, create = false }: TeamModalProps) => {
  const submit = useSubmit();
  const [teamName, setTeamName] = useState(create ? '' : team.name);
  const [selectedUsers, setSelectedUsers] = useState<Record<User['id'], User>>(
    create
      ? []
      : team.members.reduce((acc, user) => ({ ...acc, [user.id]: user }), {}),
  );

  const filteredUsers = useMemo(
    () => users.filter((user) => !selectedUsers.hasOwnProperty(user.id)),
    [users, selectedUsers],
  );

  const userOptions = useMemo(
    () =>
      filteredUsers.map((user) => ({
        label: user.fullName,
        value: user.id,
      })),
    [filteredUsers],
  );

  const handleSelectUser = useCallback(
    (id: User['id']) => {
      const user = users.find((u) => u.id === id);
      if (!user) return;
      setSelectedUsers((selectedUsers) => ({ ...selectedUsers, [id]: user }));
    },
    [setSelectedUsers, users],
  );

  const handleRemoveUser = useCallback(
    (id: User['id']) => {
      setSelectedUsers((selectedUsers) => {
        const newSelectedUsers = { ...selectedUsers };
        delete newSelectedUsers[id];
        return newSelectedUsers;
      });
    },
    [setSelectedUsers],
  );

  const enabled = useMemo(() => teamName !== '', [teamName]);

  const selectedUsersArray = useMemo(
    () =>
      Object.values(selectedUsers).sort((u1, u2) =>
        alfaCompare(u1.fullName, u2.fullName),
      ),
    [selectedUsers],
  );

  const hasChanges = useMemo(
    () =>
      teamName !== team.name ||
      selectedUsersArray.length !== team.members.length ||
      selectedUsersArray.some((user) => !team.members.includes(user)),
    [teamName, team, selectedUsersArray],
  );

  const handleSaveTeam = useCallback(() => {
    const selectedUserIDs = selectedUsersArray.map((user) => user.id);

    const data = new FormData();
    data.set('team-name', teamName);
    for (const userId of selectedUserIDs) {
      data.append('user-ids', String(userId));
    }

    submit(data, { method: 'put' });
  }, [submit, selectedUsersArray, teamName]);

  const handleDeleteTeam = useCallback(() => {
    confirmAlert({
      overlayClassName: CONFIRM_MODAL_OVERLAY_CLASSNAME,
      customUI: ({ onClose }) => (
        <DeleteConfirmModal
          resource="team"
          onClose={onClose}
          onAccept={() => submit({}, { method: 'delete' })}
        />
      ),
    });
  }, [submit]);

  const handleClose = useCallback(
    (next: () => void) => {
      if (!hasChanges) return next();

      confirmAlert({
        overlayClassName: CONFIRM_MODAL_OVERLAY_CLASSNAME,
        customUI: ({ onClose }) => (
          <LegacyConfirmModal
            title="You have unsaved changes"
            body={['Are you sure you want to close?']}
            onClose={onClose}
            onAccept={() => next()}
          />
        ),
      });
    },
    [hasChanges],
  );

  return (
    <ClientOnly>
      {() => (
        <Modal closePath=".." onClose={handleClose}>
          <ModalHeader
            title={create ? 'Create new team' : 'Edit team'}
            className="pb-2"
          >
            <div className="flex flex-1 justify-end gap-4">
              {create ? (
                <Button
                  disabled={!enabled}
                  variant="primary"
                  Icon={PlusIcon}
                  onClick={handleSaveTeam}
                >
                  Create team
                </Button>
              ) : (
                <>
                  <Button
                    disabled={!enabled}
                    className="!text-secondary-300"
                    Icon={TrashIcon}
                    onClick={handleDeleteTeam}
                  >
                    Delete
                  </Button>
                  <Button
                    disabled={!enabled || !hasChanges}
                    variant="primary"
                    Icon={ArrowDownOnSquareIcon}
                    onClick={handleSaveTeam}
                  >
                    Save
                  </Button>
                </>
              )}
            </div>
          </ModalHeader>

          <div className="flex flex-col gap-4">
            <Field
              label="Team name"
              name="name"
              placeholder="Content Team"
              value={teamName}
              onChange={(e) => setTeamName(e.target.value)}
            />

            {userOptions.length > 0 && (
              <Field
                kind="select"
                label="Add team members"
                name="name"
                options={userOptions}
                value={null}
                placeholder="Select user"
                menuPosition="fixed"
                onChange={(e) => {
                  if (e) handleSelectUser(e.value);
                }}
              />
            )}
          </div>

          {userOptions.length > 0 && (
            <div className="flex flex-col gap-4 py-8">
              {selectedUsersArray.length === 0 ? (
                <Text
                  variant="heading"
                  size="md"
                  weight="medium"
                  className="text-center"
                >
                  Add team members by selecting users from the list above ☝️
                </Text>
              ) : (
                selectedUsersArray.map((user) => (
                  <div
                    key={user.id}
                    className="align-center flex justify-between"
                  >
                    <UserInfo {...user} />

                    <Button
                      variant="icon"
                      className="!border-none !bg-transparent !text-secondary-300"
                      Icon={MinusCircleIcon}
                      onClick={() => handleRemoveUser(user.id)}
                    />
                  </div>
                ))
              )}
            </div>
          )}
        </Modal>
      )}
    </ClientOnly>
  );
};

export default TeamModal;
