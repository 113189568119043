import clsx from 'clsx';

import { PlusIcon } from '@heroicons/react/20/solid';

import type { HeroIcon } from '~/types';

import { Card } from './Layout';

type CardButtonProps = {
  Icon: HeroIcon;
  children: React.ReactNode;
  className?: string;
  onClick?: () => void;
  disabled?: boolean;
};

export const CardButton = ({
  Icon,
  children,
  className,
  onClick,
  disabled = false,
}: CardButtonProps) => (
  <Card
    className={clsx(
      'relative min-w-[350px] overflow-hidden rounded-md !bg-neutral-0 !p-0 transition-all',
      className,
      {
        'cursor-not-allowed shadow-none': disabled,
        'hover:translate-y-[-2px] hover:shadow-drop active:translate-y-[0] active:!bg-neutral-100 active:shadow-none':
          !disabled,
      },
    )}
  >
    <button
      className={clsx('flex w-full items-center gap-5', {
        'pointer-events-none': disabled,
      })}
      onClick={onClick}
    >
      <div
        className={clsx(
          'flex aspect-square h-full items-center justify-center border-r border-neutral-250 bg-neutral-50',
        )}
      >
        <Icon className={clsx('h-5 w-5 text-neutral-500')} />
      </div>
      <div className="flex w-full flex-col !items-start justify-center py-3 pr-4 !text-start">
        {children}
      </div>
    </button>
  </Card>
);

type CreateNewResourceButtonProps = {
  title: string;
  subtitle: string;
  className?: string;
  disabled?: boolean;
};

export const CreateNewResourceButton = ({
  title,
  subtitle,
  className,
  disabled = false,
}: CreateNewResourceButtonProps) => (
  <CardButton
    Icon={PlusIcon}
    className={clsx('items-centers flex h-full', {
      '!bg-neutral-100 !shadow-none': disabled,
    })}
    disabled={disabled}
  >
    <div className={clsx('flex flex-col text-left', className)}>
      <p
        className={clsx('text-sm font-medium leading-[150%] text-neutral-600', {
          'text-neutral-450': disabled,
        })}
      >
        {title}
      </p>
      <p
        className={clsx('text-xs leading-[150%] text-neutral-450', {
          'text-neutral-400': disabled,
        })}
      >
        {subtitle}
      </p>
    </div>
  </CardButton>
);
