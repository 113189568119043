import React, { useRef } from 'react';

import clsx from 'clsx';
import { useField } from 'remix-validated-form';

import type { FieldArrayProps } from 'remix-validated-form';
import type { SingleValue } from 'react-select';

import type { Role } from '~/models';
import type { Necessity } from '~/types';

import { useUser } from '~/providers';

import { hasRolePermission } from '~/utils';

import { useFieldDiffSetter } from './FieldDiffProvider';
import InputError from './InputError';
import Label from './Label';
import { FieldInfoTooltip } from './InfoTooltip';

export type ValidatedFieldProps<T extends string> = {
  name: T;
  label?: string;
  faded?: string;
  necessity?: Necessity;
  description?: string;
  className?: string;
  errorClassName?: string;
  labelClassName?: string;
  children: React.ReactElement;
  roles?: Role[];
  onChange?: (
    e: React.ChangeEvent<HTMLInputElement> | SingleValue<any>,
  ) => void;
  onKeyDown?: (e: React.KeyboardEvent<HTMLInputElement>) => void;
  value?: string | number;
  type?: HTMLInputElement['type'];
  hidden?: boolean;
  validationBehavior?: FieldArrayProps<T>['validationBehavior'];
  tooltip?: { title: string; paragraphs: string[] };
};

export function ValidatedField<T extends string>({
  name,
  label,
  faded,
  description,
  necessity = 'normal',
  className,
  errorClassName,
  labelClassName,
  children,
  roles = [],
  value,
  type = 'text',
  hidden = false,
  onChange,
  onKeyDown,
  validationBehavior,
  tooltip,
}: ValidatedFieldProps<T>) {
  const { user } = useUser();
  const isAllowed = hasRolePermission({ roles, userRoles: user?.roles ?? [] });

  const { error, getInputProps, defaultValue } = useField(name, {
    validationBehavior,
  });

  const setFormDiff = useFieldDiffSetter();

  const ref = useRef<HTMLInputElement>();

  if (!isAllowed || hidden) {
    return (
      <input
        {...getInputProps({ id: name, type, ...(value && { value }) })}
        type="hidden"
      />
    );
  }

  return (
    <div className={clsx('flex w-full flex-col gap-1', className)}>
      <Label htmlFor={name} className={clsx(labelClassName)}>
        <LabelComponent label={label} faded={faded} necessity={necessity} />
        {tooltip && (
          <span className="inline-flex">
            <FieldInfoTooltip {...tooltip} />
          </span>
        )}
      </Label>

      <DescriptionComponent description={description} />

      {React.cloneElement(children, {
        ...getInputProps({
          id: name,
          type,
          ...(value && { value }),
          onChange: (
            e: React.ChangeEvent<HTMLInputElement> | SingleValue<any>,
          ) => {
            let value = e.target?.value;

            if (type === 'select') {
              value = e.value;
            }

            let hasDiff = defaultValue
              ? value !== defaultValue.toString()
              : value.length > 0;

            if (type === 'checkbox') {
              value = e.target.checked;
              hasDiff = defaultValue ? value !== defaultValue : value;
            }

            setFormDiff(name, hasDiff);
            if (onChange) onChange(e);
          },
        }),
        onKeyDown: (e: React.KeyboardEvent<HTMLInputElement>) => {
          if (onKeyDown) onKeyDown(e);
        },
        ...(type === 'number' && { ref }),
      })}

      <ErrorElement error={error} errorClassName={errorClassName} />
    </div>
  );
}

// util components
const ErrorElement = ({
  error,
  errorClassName,
}: {
  error?: string;
  errorClassName?: string;
}) =>
  error ? (
    <InputError className={clsx(errorClassName)}>{error}</InputError>
  ) : null;

type LabelProp = {
  label?: string;
  faded?: string;
  necessity: Necessity;
  disabled?: boolean;
};
const LabelComponent = ({ label, faded, necessity, disabled }: LabelProp) => {
  if (!label) {
    return null;
  }

  return (
    <>
      <span
        className={clsx('text-sm font-medium leading-normal text-neutral-600', {
          'text-neutral-600/30': disabled,
        })}
      >
        {label}
      </span>

      <span className="ml-2 text-sm font-normal leading-normal text-neutral-450">
        {faded}
      </span>

      {necessity === 'required' && (
        <span className="text-secondary-300">*</span>
      )}
      {necessity === 'optional' && (
        <span className="text-grey">(optional)</span>
      )}
    </>
  );
};

const DescriptionComponent = ({ description }: { description?: string }) => {
  if (!description) {
    return null;
  }

  return (
    <span className="text-sm leading-normal text-neutral-400">
      {description}
    </span>
  );
};
