import clsx from 'clsx';

import type { HeroIcon } from '~/types';

type ChipProps = {
  children: React.ReactNode;
  selected?: boolean;
  Icon?: HeroIcon;
  className?: string;
  iconClassName?: string;
  textClassName?: string;
};
const Chip = ({
  children,
  selected = false,
  Icon,
  className,
  iconClassName,
  textClassName,
}: ChipProps) => (
  <div
    className={clsx(
      'flex max-h-[25px] w-max items-center justify-center gap-1 rounded-sm border border-neutral-250 bg-neutral-50 px-1.5 py-0.5',
      className,
      { 'bg-neutral-150': selected },
    )}
  >
    <div className="flex items-center gap-1">
      {Icon && (
        <Icon
          className={clsx('h-2.5 w-2.5', iconClassName, {
            'text-neutral-500': selected,
            'text-neutral-600': !selected,
          })}
        />
      )}

      <p className={clsx('text-xs')}>{children}</p>
    </div>
  </div>
);

export default Chip;
