import React, { useMemo } from 'react';

import clsx from 'clsx';
import zxcvbn from 'zxcvbn';

const classNames = ['', 'bg-red', 'bg-proper-orange', 'bg-orange', 'bg-green'];

const PasswordStrength = ({ password = '' }) => {
  const passwordStrength = useMemo(() => {
    if (password.length === 0) {
      return 0;
    }

    const score = Math.max(zxcvbn(password).score, 1);

    if (password?.length > 12 && score > 3) {
      return 4;
    }

    return Math.max(score - 1, 1);
  }, [password]);

  return (
    <div className="-mx-2 mt-2 flex items-center">
      {[1, 2, 3, 4].map((value) => (
        <div className="flex-1 px-2" key={value}>
          <div
            className={clsx('h-2 rounded-full', {
              'bg-grey-dark': passwordStrength < value,
              [classNames[passwordStrength]]: passwordStrength >= value,
            })}
           />
        </div>
      ))}
    </div>
  );
};

export default PasswordStrength;
