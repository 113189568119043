import clsx from 'clsx';

import { Text } from '~/components';

const Count = ({ count, className }: { count: number; className?: string }) => (
  <span
    className={clsx(
      'ml-auto rounded-lg bg-neutral-150 px-2 py-0.5 group-hover:bg-neutral-250',
      className,
    )}
  >
    <Text className="text-xs !leading-[20.5px]">{count}</Text>
  </span>
);

export default Count;
