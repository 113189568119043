import React, { useEffect, useMemo, useState } from 'react';

import type { FormMethod, useFetcher } from '@remix-run/react';

import { useFormContext, ValidatedForm } from 'remix-validated-form';
import clsx from 'clsx';
import { v4 as uuid } from 'uuid';

import type { Validator } from 'remix-validated-form';

import Button from '../Button';
import {
  FieldDiffProvider,
  useFieldDiffReset,
  useFieldDiffSetter,
  useFieldDiffState,
} from './FieldDiffProvider';

type Children = React.ReactNode[] | React.ReactNode;
type ChildrenProps = { disabled: boolean; loading: boolean; data: FormData };

type FormWithDiffProps<
  DataType extends {
    [fieldName: string]: any;
  },
> = {
  children: Children | ((e: ChildrenProps) => Children);
  validator: Validator<DataType>;
  method: FormMethod;
  id?: string;
  defaultValues?: Partial<DataType>;
  controlledValues?: Partial<DataType>;
  className?: string;
  CustomSubmit?: React.ElementType<Omit<ChildrenProps, 'data'>>;
  externalSubmitBtn?: boolean;
  fetcher?: ReturnType<typeof useFetcher<DataType>>;
  action?: string;
  onSubmit?: (data: DataType, event: React.FormEvent<HTMLFormElement>) => void;
  onChange?: (data: FormData) => void;
  resetDiffOnSubmit?: boolean;
  submitTitle?: string;
  subaction?: string;
  skipChangeOnHiddenInput?: boolean;
  ref?: React.Ref<HTMLFormElement>;
};

function FormWithDiff<
  DataType extends {
    [fieldName: string]: any;
  },
>({
  id = uuid(),
  children,
  validator,
  method,
  defaultValues,
  controlledValues,
  className,
  CustomSubmit,
  externalSubmitBtn = false,
  fetcher,
  action,
  onSubmit,
  resetDiffOnSubmit = false,
  submitTitle = 'Submit',
  onChange,
  skipChangeOnHiddenInput,
  ...rest
}: FormWithDiffProps<DataType>) {
  const [canReset, setCanReset] = useState(false);
  const hasChanges = useFieldDiffState();
  const resetForm = useFieldDiffReset();
  const setFormDiff = useFieldDiffSetter();
  const context = useFormContext(id);

  const isValid = useMemo(() => {
    // useFieldArray causes some issues when using REPLACE
    // to update the item, it also detects the ID field
    // as TOUCHED which then invalidates the context
    const fieldErrors = Object.keys(context.fieldErrors).filter(
      (item) => !item.includes('id'),
    );

    return fieldErrors.length === 0 ? true : context.isValid;
  }, [context]);

  const canSubmit =
    ((skipChangeOnHiddenInput ?? hasChanges) &&
      isValid &&
      !context.isSubmitting) ||
    context.fieldErrors.hasOwnProperty('allow_submit');

  useEffect(() => {
    if (controlledValues) {
      Object.keys(controlledValues).forEach((key) => {
        // @ts-ignore - not sure how to type this better atm
        setFormDiff(key, defaultValues[key] !== controlledValues[key]);
      });
    }
  }, [controlledValues, defaultValues, setFormDiff]);

  useEffect(() => {
    // only reset the form if the default values have changed and the submit was not prevented
    if (resetDiffOnSubmit && canReset) {
      resetForm();
      context.reset();
      setCanReset(false);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [context.defaultValues]);

  return (
    <ValidatedForm
      id={id}
      validator={validator}
      // @ts-expect-error
      defaultValues={defaultValues}
      method={method}
      className={clsx(className)}
      fetcher={fetcher}
      action={action}
      onSubmit={(data, event) => {
        onSubmit?.(data, event);

        if (event.defaultPrevented) {
          setCanReset(false);
          return;
        }

        setCanReset(true);
      }}
      onChange={() => onChange && onChange(context.getValues())}
      {...rest}
    >
      {typeof children === 'function'
        ? children({
            disabled: !canSubmit,
            loading: context.isSubmitting,
            data: context.getValues(),
          })
        : children}

      {!externalSubmitBtn && CustomSubmit && (
        <CustomSubmit disabled={!canSubmit} loading={context.isSubmitting} />
      )}
      {!externalSubmitBtn && !CustomSubmit && (
        <Button type="submit" className="mt-4">
          {submitTitle}
        </Button>
      )}
    </ValidatedForm>
  );
}

export function DiffableForm<
  DataType extends {
    [fieldName: string]: any;
  },
>(props: FormWithDiffProps<DataType>) {
  return (
    <FieldDiffProvider>
      <FormWithDiff {...props}>{props.children}</FormWithDiff>
    </FieldDiffProvider>
  );
}
