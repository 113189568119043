import { useState } from 'react';

import { v4 as uuid } from 'uuid';

import { PlusIcon, TrashIcon } from '@heroicons/react/24/solid';

import type { InputProps } from 'react-select';

import Button from '../Button';
import Input from './Input';

export type GenericObject = {
  [key: string]: string;
};

export type ObjectArrayInputProps = InputProps & {
  defaultValue?: GenericObject[];
  onChange?: (
    updateObj:
      | GenericObject[]
      | {
          value: GenericObject;
          index: number;
        },
  ) => void;
  fields: Array<{
    label: string;
    name: string;
  }>;
};

type ArrayInputValue = {
  id: string;
  value: GenericObject;
};

const ObjectArrayInput = ({
  defaultValue = [],
  fields,
  onChange,
  ...props
}: ObjectArrayInputProps) => {
  const [controlledValue, setControlledValue] = useState<GenericObject>({});
  const [arrayValues, setArrayValues] = useState<ArrayInputValue[]>(
    () => defaultValue?.map((val) => ({ id: uuid(), value: val })) || [],
  );

  const invalidInput =
    fields.filter(({ name }) => !controlledValue[name]?.length).length > 0;

  return (
    <>
      {arrayValues.map(({ id, value }, index) => (
        <div key={id} className="flex items-center justify-between gap-2">
          {fields.map(({ name }) => (
            <Input
              key={`id-${name}`}
              {...props}
              {...(onChange && {
                onChange: (e) => {
                  value[name] = e.target.value;
                  onChange({ value, index });
                },
              })}
              autoComplete={props.name}
              defaultValue={value[name]}
              containerClassName="w-full"
              name={`${props.name}[${name}]`}
            />
          ))}

          <Button
            Icon={TrashIcon}
            variant="icon"
            iconClassName="text-secondary-300"
            onClick={() => {
              const updatedArray = arrayValues.filter((item) => item.id !== id);
              setArrayValues(updatedArray);
              onChange && onChange(updatedArray.map(({ value }) => value));
            }}
          />
        </div>
      ))}

      <div className="flex items-center justify-between gap-2">
        {fields.map(({ label, name }) => (
          <Input
            key={`new-${name}`}
            {...props}
            ref={undefined}
            containerClassName="w-full"
            value={controlledValue[name] ?? ''}
            placeholder={label}
            onChange={(e) => {
              const newValue = controlledValue;
              newValue[name] = e.target.value;
              setControlledValue(newValue);
              onChange &&
                onChange({ value: newValue, index: arrayValues.length });
              e.stopPropagation();
            }}
          />
        ))}

        <Button
          disabled={invalidInput}
          variant="icon"
          Icon={PlusIcon}
          onClick={() => {
            setArrayValues((prev) => [
              ...prev,
              { id: uuid(), value: controlledValue },
            ]);
            onChange &&
              onChange({ value: controlledValue, index: arrayValues.length });
            setControlledValue({});
          }}
        />
      </div>
    </>
  );
};

export default ObjectArrayInput;
