import { useRef } from 'react';

function usePreviousValue<T>(value: T): T {
  const ref: any = useRef<{ value: T; prev: T | undefined }>({
    value: value,
    prev: undefined,
  });

  const current = ref.current.value;

  if (current !== value) {
    ref.current = {
      value,
      prev: current,
    };
  }

  return ref.current.prev;
}

export default usePreviousValue;
